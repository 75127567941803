import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Api, Code } from '@.api';
import { useForm } from '@.hooks';
import { validateOptions } from '@/Components/Pages/Data/Products/Prices/Price/validate';
import { PricesData } from '@/Components/Pages/Data/Products/Prices/types';
import { Price } from '@/Components/Partials/Prices';
import { Controls, PriceValue } from '@/Components/Partials/Prices/Price/types';
import { isObject } from '@/Lib';
import { Auth } from '@/Services';
import { DictionaryResponseKnown } from '@/Types/api/Dictionaries';
import { PriceData } from '@/Types/Prices';

export interface GetPricesRef {
    getPrices: () => PriceData[] | undefined;
}

type PricesFormProps = {
    title: string;
    className?: string;
    initialValue?: PriceData[];
};

export const PricesForm = forwardRef<GetPricesRef, PricesFormProps>(({ title, className, initialValue }, ref) => {
    const auth = Auth.Use();
    const [availableStores, setAvailableStores] = useState<DictionaryResponseKnown['stores']>([]);
    const [availableCurrencies, setAvailableCurrencies] = useState<Record<number, DictionaryResponseKnown['currencies']>>({});
    const [field, form] = useForm<PricesData>();
    const controls = useRef<Record<string, Controls>>({});

    useEffect(() => {
        form.reset.toInitial();

        if (initialValue?.length) {
            form.set.values(
                Object.fromEntries(
                    initialValue.map((price) => {
                        const uuid = uuidv4();

                        return [uuid, { data: price, uuid }];
                    })
                )
            );

            return;
        }

        const uuid = uuidv4();

        field(uuid).value({
            uuid,
            data: {},
        });
    }, [initialValue]);

    useEffect(() => {
        const companyId = auth?.user?.company?.id;

        const getCurrencies = async () => {
            const response = await Api.dictionaries().byNames(['Stores'], {
                ...(companyId
                    ? {
                          stores: {
                              company_id: companyId,
                          },
                      }
                    : {}),
            });

            if (response.code !== Code.Success) {
                return;
            }

            const stores = response.data.stores || [];

            setAvailableStores(stores);

            const storeRequests = stores.map(async (store) => {
                const response = await Api.dictionaries().byNames(['Currencies'], {
                    currencies: {
                        store_id: store.id,
                    },
                });

                if (response.code !== Code.Success) {
                    return [];
                }

                return response.data.currencies || [];
            });

            const storeResponses = await Promise.all(storeRequests);

            setAvailableCurrencies(
                storeResponses.reduce(
                    (acc, currencies, index) => ({ ...acc, [stores[index].id]: currencies }),
                    {} as Record<number, DictionaryResponseKnown['currencies']>
                )
            );
        };

        getCurrencies();
    }, [auth?.user?.company?.id]);

    const findDuplicatesAndGetPrices = () => {
        const list: PriceData[] = [];

        const values = Object.values(form.state).filter((v) => v !== undefined && typeof v === 'object' && v.value !== undefined);

        const seen: Record<
            string,
            PriceData & {
                errors: (errors: Record<string, string> | string) => void;
                index: number;
            }
        > = {};

        let errored = false;

        values.forEach((item, index) => {
            if (typeof item === 'string' || !item?.value) {
                return;
            }

            const itemControls = controls.current[item.value.uuid];

            if (!itemControls) {
                return;
            }

            const { ifValid, clearErrors, errors } = itemControls;

            try {
                clearErrors();
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }

            ifValid((data) => {
                const duplicateItem = {
                    ...data,
                    volume_fl_oz: data.volume_fl_oz && Math.ceil(data.volume_fl_oz * 10) / 10,
                    volume_ml: data.volume_ml && Math.ceil(data.volume_ml),
                };

                const dubs = Object.values(seen).filter((value) => {
                    const { currency_id, price, volume_fl_oz, volume_ml } = value;

                    return currency_id === data.currency_id && price === data.price && volume_fl_oz === data.volume_fl_oz && volume_ml === data.volume_ml;
                });

                if (dubs.length !== 0) {
                    dubs.push({ ...duplicateItem, errors, index: index + 1 });
                }

                dubs.forEach((value) => {
                    value.errors(
                        `Duplicate of ${dubs
                            .filter((val) => value.index !== val.index)
                            .map((value) => `#${value.index}`)
                            .join(', ')}`
                    );
                });

                if (dubs.length !== 0) {
                    errored = true;
                }

                seen[(item.value as PriceValue)?.uuid] = { ...duplicateItem, errors, index: index + 1 };

                list.push(duplicateItem);
            }, validateOptions);
        });

        if (list.length !== values.length || errored) return;

        return list;
    };

    const handleDelete = (uuid: string) => {
        field(uuid).value(undefined);
    };

    const handleAdd = () => {
        const uuid = uuidv4();

        field(uuid).value({
            uuid,
            data: {},
        });
    };

    const handleControls = (uuid: string, newControls: Controls) => (controls.current[uuid] = newControls);

    useImperativeHandle(ref, () => ({ getPrices: findDuplicatesAndGetPrices }));

    const prices = Object.values(form.state).filter((priceItem) => isObject(priceItem) && Boolean(priceItem?.value)) as {
        value: PriceValue;
    }[];

    return (
        <div className={className}>
            <h1>{title}</h1>
            <div className="action-form">
                {prices.map((item, index) => {
                    const priceItem = item.value;

                    const storeId = priceItem.data.store_id;

                    return (
                        <Price
                            uuid={priceItem.uuid}
                            key={`price-${priceItem.uuid}-${priceItem.data.store_id}`}
                            currencies={storeId ? availableCurrencies[storeId] || [] : []}
                            stores={availableStores || []}
                            onAdd={handleAdd}
                            onDelete={handleDelete}
                            priceActionVariant={index === 0 ? 'add' : 'delete'}
                            controls={handleControls}
                            {...field(item.value.uuid).register<PriceValue>()}
                        />
                    );
                })}
            </div>
        </div>
    );
});
